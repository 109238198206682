<!--修改自己密码页面-->
<template>
    <div class="view">
        <el-form label-position="left">
            <el-form-item :label="lang['html.el-form-item.newPass']">
                <el-input v-model="newPass" type="password"/>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.tempPass']">
                <el-input v-model="tempPass" type="password"/>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submit" :disabled=isDisabled>{{lang['html.button.submit']}}</el-button>
                <el-button @click="newPass = '';tempPass = '';" :disabled=isDisabled>{{lang['html.button.reset']}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "setSelfNewPass",
    data(){
        return {
            newPass:'',
            tempPass:'',
            lang:this.$lang.getLang('agent','setSelfNewPass'),
            isDisabled:false,
        }
    },
    methods:{
        submit(){
            if (this.newPass.length <=1 || this.tempPass.length <=1){
                this.$alert(this.lang['submit.error.length']);
                return;
            }
            if (this.newPass !== this.tempPass){
                this.$alert(this.lang['submit.error.tempPass']);
                return;
            }
            this.isDisabled = true;
            this.$notify({message:this.lang['submit.wait']});
            this.$api.post('Agent.AgentData/setSelfNewPass',{newPass:this.newPass}).then(res=>{
                this.isDisabled = false;
                let data = this.$helper.checkRes(res,true);
                if (data){
                    this.$alert(this.lang['submit.successful'],null,{type:'success'});
                    //执行成功,让代理商重新登录
                    this.loginOut();
                }
            }).catch(err=>{
                this.isDisabled = false;
                this.$helper.axiosCatch(err);
            })
        },
        /**
         * 退出登录
         */
        loginOut(){
            this.$cookie.setToken('');
            let params = {};
            let version = this.$cookie.getCookie('version');
            if (version == 1){
                params.version = 'c';
            }else if (version == 2){
                params.version = 'y';
            }else {
                params.version = 'error';
            }
            this.$router.push({name:'login',params})
        },
    }
}
</script>

<style scoped>
.view{
    width: 300px;
    margin:0 auto;
    margin-top: 20px;
}

</style>